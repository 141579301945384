import React from "react"	
import { graphql, StaticQuery,Link } from 'gatsby'	
import { navigate } from "@reach/router";
import { ArrowLeft } from "react-feather";
export default () => (
    <StaticQuery
      query={graphql`
        query SpeakingQuery {
          site {
            siteMetadata {
              speaking{
                  title
                  event_name
                  event_link
                  slide_link
                  event_date
                  event_location
              }
            }
          }
        }
      `}
      render={(data) => (
        // <Layout>

    <div className="site-wrapper">
        <div className="site-title">
        <p
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          style={{ cursor: "pointer" }}
        >
          <span>
            <ArrowLeft />
          </span>
        </p>
          <h2 >
            <Link  to="#">Public Speaking</Link>
          </h2>
        </div>
        <br/>
        <div className="hero-header">
         {/* <br />
          <br />
          <br />
           <div className="headline">{data.site.siteMetadata.home.title}</div>
          <br /> */}
        
          {data.site.siteMetadata.speaking.map(
            (data,index)=>{return <div className="primary-content" style={{marginBottom:10}}>
            <ol>
                <a className="public-speaking" href={data.slide_link} 
                /*I have already moved this into css. I still kept it here in case I need it in future and too lazy to google stuff
                style={{fontWeight: 'bold',fontSize:'18px', textDecoration:'underline'}} */
                    href={data.slide_link}>{data.title}</a>
                <ul>
                <a href={data.event_link}>{data.event_name}</a>
                </ul>
                <ul>
                {data.event_location} - {data.event_date}
                </ul>
            </ol>
            </div>}
            )
          }
        <br />
        <br />
        <br />
  
        {/* <h2><u>Public Speaking</u></h2> */}
         
          <br />
        </div>
        </div>
        // </Layout>
      )}
    />
    
  );